function popupText(elem){
	var header = elem.data("header");
	var description = elem.data("descr");
	var btntext = elem.data("btn")
	var el = elem.data("modal");
	var id = "#"+el;

	$(id).find('.h3').html(header);
	$(id).find('.desc').html(description);
	$(id).find('input[type=submit]').val(btntext);
}

$(document).foundation();
$(document).ready(function() {

	$('.animated').each(function(){
      var imagePos = $(this).offset().top;
      var topOfWindow = $(window).scrollTop();
      var windowHeight = $(window).height();
      if($(this).data("anim-begin")){
          var heightBegin = $(this).data("anim-begin");
      }
      else{
          heightBegin = 0.8;
      }
      var beginView = windowHeight*heightBegin;
      var classAnimate = $(this).data("anim");
      if (imagePos < topOfWindow+beginView) {
        $(this).addClass(classAnimate+' done');
      }
    });

    $('.goods-slider').slick({
      autoplay: true,
      autoplaySpeed: 8000,
      slidesToShow: 3,
      slidesToScroll: 3,
      arrows: false,
      dots: true,
      centerPadding: '30px',
      infinite: true,

      responsive: [
        {
          breakpoint: 1023,
          settings: {
            centerPadding: '30px',
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 640,
          settings: {
            slidesToScroll: 1,
            slidesToShow: 1
          }
        }
      ]
    }); //End Slick Facts

    $('.slider-certs').slick({
      autoplay: false,
      autoplaySpeed: 8000,
      slidesToShow: 2,
      slidesToScroll: 2,
      arrows: false,
      dots: true,
      centerPadding: '30px',
      variableWidth: true,
      infinite: true,

      responsive: [
        {
          breakpoint: 770,
          settings: {
            centerPadding: '30px',
            slidesToShow: 2
          }
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            variableWidth: false,
            adaptiveHeight: true,
            slidesToScroll: 1
          }
        }
      ]
    }); //End Slick Facts

    $('.slider-works').slick({
      autoplay: false,
      autoplaySpeed: 8000,
      slidesToShow: 1,
      arrows: false,
      dots: true,
      infinite: true,
      adaptiveHeight: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            autoplay: false,
            adaptiveHeight: true
          }
        }
      ]
    }); //End Slick Works

    $('.certs .slick-track').magnificPopup({
      delegate: 'a',
      type: 'image',
      closeOnContentClick: true,
      closeBtnInside: false,
      showCloseBtn: false,
      mainClass: 'mfp-no-margins mfp-with-zoom mfp-img-mobile',
      image: {
        verticalFit: true
      },
      gallery: {
        enabled: false,
        navigateByImgClick: true
      },
      zoom: {
        enabled: true,
        duration: 300, 
        opener: function(element) {
          return element.find('img');
        }
      }
      
    });

    $(".ask").click(function() {
    	popupText($(this));
    	$('#modal-ask').foundation('open');
    });

    $('.header-call-back, .offer-hello, .popup-link, .offer-steps').on('click', function() {
    	popupText($(this));
	  	$('#modal-offer').foundation('open');
	});

});

$(window).scroll(function() {
    $('.animated').each(function(){
      var imagePos = $(this).offset().top;
      var topOfWindow = $(window).scrollTop();
      var windowHeight = $(window).height();
      if($(this).data("anim-begin")){
          var heightBegin = $(this).data("anim-begin");
      }
      else{
          heightBegin = 0.8;
      }
      var beginView = windowHeight*heightBegin;
      var classAnimate = $(this).data("anim");
      if (imagePos < topOfWindow+beginView) {
        $(this).addClass(classAnimate+' done');
      }
    });
});